<template>
  <div ref="scrollRef" class="index-page">
    <NewkiHeaderBar />
    <NewkiPullRefreshLoading v-model="loading" @refresh="refresh">
      <div class="fresh-box" style="min-height: 120vh" v-if="!loading">
        <template v-for="item in content" :key="item.id">
          <Render :name="item.name" :wData="item" />
        </template>
        <Recommend mode="light" />
        <div class="icp">{{ icpCode }}</div>
      </div>
    </NewkiPullRefreshLoading>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onActivated, onMounted } from 'vue'

import NewkiHeaderBar from '~/components/NewkiHeaderBar.vue'
import Recommend from '~/components/Recommend.vue'
import Render from '~/components/render/Render.vue'
import { getHomeContentBySiteId } from '~/service/api/home'
import { useSystemStore } from '~/store/system'
const { icpCode } = storeToRefs(useSystemStore())

const scrollRef = ref(null)
const route = useRoute()

usePagePosition(scrollRef)

useScrollEvent('.index-page')

definePageMeta({
  layout: 'home',
  keepalive: true,
})

const loading = ref(true) // 數據加載中

const content: any = ref(true)

// 服務端調用：useLazyAsyncData = useAsyncData + lazy:true
const { data } = await useAsyncData(`getHomeContentFetch-${useNanoid(6)}`, () => getHomeContentBySiteId(), {
  server: true,
  lazy: true,
  default: () => ({
    content: [],
    loading: true,
  }),
})

watch(
  data,
  val => {
    if (val?.content) {
      try {
        content.value = JSON.parse(val?.content)
      } catch {
        content.value = []
      }
    } else {
      content.value = []
    }
  },
  { immediate: true }
)

watch(content, val => {
  if (val.length > 0) {
    loading.value = false
  }
})

const freshData = async () => {
  let data = await getHomeContentBySiteId()
  if (data?.content) {
    try {
      content.value = JSON.parse(data?.content)
    } catch {
      content.value = []
    }
    loading.value = false
  } else {
    content.value = []
    loading.value = false
  }
}

async function refresh() {
  loading.value = true
  freshData()
}
</script>

<style scoped lang="scss">
.index-page {
  @include scrollbar();
  height: 100vh;
  position: relative;
  z-index: 9;
  padding: 56PX 0 0px;
  background-color: #fff;

  .content-box {
    width: 100%;
    height: auto;
  }

  .item-box {
    position: relative;
    z-index: 10;
  }

  :deep(.recommend-container) {
    .recommend-content {
      padding-bottom: 120px;
    }
  }
}

.fresh-box {
  position: relative;
  z-index: 10;
  .icp {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 95px;
    z-index: 10;
    font-size: 13px;
    color: $text-color-assist;
    width: 100%;
    text-align: center;
  }
}
</style>
